<template>
   <div class="cont">
      <el-row>
         <el-link class="l-height-50 back_iconfont" type="primary" icon="iconfont iconfanhui1" @click="goBack">
            <span>同步人脸识别设备</span>
         </el-link>
      </el-row>
      <el-row class="synchro-main">
         <el-row><img src="@/assets/u1229.png" alt=""></el-row>
         <el-row>开始同步赛菲姆云-人脸识别设备</el-row>
         <el-row><el-button type="primary" @click="handleSynchro">开始同步</el-button></el-row>
      </el-row>
   </div>
</template>

<script>
   import { urlObj } from '@/api/interface'
   export default {
      data() {
         return {
            devices: [],
            page: 1,
            limit: 10,
         }
      },
      mounted() {
         this.hotelId = this.$route.query.hotelId
      },
      methods: {
         // 同步设备
         handleSynchro(){
            const url = urlObj.synchroDevices +`?limit=${this.limit}&page=${this.page}`
            const param = {
               hotelId: this.hotelId
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.devices = res.records
                  sessionStorage.setItem('devices', JSON.stringify(this.devices))
                  this.$router.replace({path: '/device', query:{'hotelId': this.hotelId}})
               }
            })
         },
         // 返回上页
         goBack() {
            this.$router.replace('/hotel_manage')
         },
      }
   }
</script>
<style lang="scss" scoped>
   .cont{
      .synchro-main{
         text-align: center;
         font-size: 30px;
         padding: 0 0 60px 0;
      }
      .synchro-main div{
         margin: 40px 0;
      }
   }
</style>
